.size-run-collapse{
  min-height: 64px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  .size-run-actions{
    display: flex;
    flex-direction: row;
    &.visible-actions{
      .toggled-part{
        padding-left: 8px;
        width: 226px;
        transition: 0.2s;
      }
      &.actionsX2{
        .toggled-part{
          width: 226px;
        }
      }
      &.actionsX1{
        .toggled-part{
          width: 113px;
        }
      }
      &.actionsX0{
        .toggled-part{
          width: 0;
        }
      }
    }
    .ant-btn:not(.toggled-part .ant-btn){
      outline: none;
      border: none;
      box-shadow: none;
    }
    .toggled-part{
      overflow: hidden;
      display: flex;
      width: 0;
      transition: 0.2s;
      column-gap: 8px;
      white-space: nowrap;
      .ant-btn{
        outline: none
      }
    }
  }
  &.has-expanded-content{
    border-radius: 8px 8px 0 0;
    transition: 0.3s;
  }
  .card-head{
    display: flex;
    justify-content: space-between;
    height: 64px;
    padding: 16px;
    .left{
      display: flex;
      align-items: center;
      column-gap: 8px;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
      .checkbox-department{
        margin-top: -3px;
        &.with-error {
            .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) {
              &::after {
                border-color: @dart-danger;
              }
              .ant-checkbox-inner {
                background-color: @dart-danger;
              }
            }
            &.withoutDisabledStyle .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner{
              background-color: fade(@dart-danger, 100%) !important;
            }
            .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled {
              .ant-checkbox-inner {
                background-color: fade(@dart-danger, 20%);
              }
            }
        }
      }
      .title{
        font-family: 'GothamBold', sans-serif;

        font-weight: 450;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        text-transform: uppercase;
      }
      .vertical-split{
        border: 1px solid #C3C5C8;
        height: 16px;
      }
      .size-indicator{
        background: @dart-dark;
        border-radius: 8px;
        color: white;
        height: 32px;
        padding: 7px 12px;
        cursor: pointer;
        span {
          font-family: 'GothamBook', sans-serif;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .right{
      display: flex;
      align-items: center;
      cursor: pointer;
      column-gap: 24px;
      height: 64px;
      margin-top: -16px;
      user-select: none;
      min-width: 120px;
      justify-content: flex-end;
      .content{
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        color: @dart-mid-gray;
      }
      .collapse-arrow *{
        fill: #000000;
      }
    }
  }
  .expand-content{
    width: 100%;
    height: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: 0.3s;
    opacity: 0;
    position: relative;
    &.visible{
      height: 184px;
      transition: 0.3s;
      margin-top: -16px;
      opacity: 1;
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";