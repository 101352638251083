.dart-icon {
  display: flex;
  align-items: center;
  //noinspection CssInvalidPropertyValue
  svg,
  g,
  path {
    transition: 0.2s;
    fill: @dart-mid-gray;
  }
  &.has-color {
    //noinspection CssInvalidPropertyValue
    svg,
    g,
    path {
      transition: 0.2s;
      fill: inherit;
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";