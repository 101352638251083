.alloc-q-review-page {
  .alloc-q-overview-widget{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .update-review-section{
    margin-top: 0;
    h2{
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 20px;
      line-height: 16px;
      text-transform: uppercase;
      color: @dart-dark;
    }
    hr{
      border: 0;
      border-bottom: 1px solid @dart-gray-alt;
      margin: 16px 0;
    }
    .alloc-q-overview-widget{
      margin-top: 0;
      max-height: calc(100vh - 269px);
      overflow: scroll;
    }
  }
}

// used for top header
.main-layout-content {
  .alloc-q-review-page{
    padding-top: 128px;
    .alloc-q-overview-widget{
      max-height: unset;
      overflow: initial;
    }
    .container-content{
      padding: 0 64px;
      .variance-wrapper {
        width: 100%;
        //margin-top: 20px; //@TODO: temporarily removed
        // temp here
        margin-top: -120px;
        display: flex;
        justify-content: flex-start;
        column-gap: 32px;
        .dart-select{
          display: flex;
          column-gap: 8px;
          align-items: center;
          .ant-select-arrow{
            top: 15px;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
            line-height: 33px;
          }
        }
        // till here
        .dart-input {
          display: flex;
          align-items: center;
          column-gap: 16px;
          label {
            width: fit-content;
            white-space: nowrap;
            padding-left: 0;
          }
          input {
            max-width: 60px;
            min-width: 60px;
            text-align: center;
            padding-left: 6px;
            padding-right: 6px;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";