.set-password-form {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  margin-top: -40px;
  .logo-wrap{
    text-align: center;
    margin-bottom: 32px;
    margin-top: -32px;
    cursor: pointer;
    img{
      width: 100px;
    }
  }
  .inner-wrap{
    width: 300px;
    margin: 0 auto;
  }

  .error-wrapper{
    ul{
      padding: 0 15px;
      margin: 0;
    }
  }

  .set-password-header{
    text-align: center;
    font-size: 24px;
    font-family: 'GothamBold', sans-serif;
    font-weight: 800;
  }
  .set-password-subheader{
    margin-bottom: 60px;
    text-align: center;
    font-size: 18px;
    font-family: 'GothamLight', sans-serif;
    font-weight: 800;
  }

  .anticon {
    * {
      fill: #959A9D;
    }
  }

  .dart-button {
    width: 100%;

    button {
      width: 100%;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 8px;
    height: 36px;

    input::placeholder {
      color: @dart-dark;
      opacity: 0.5;
    }

    &:focus,
    &:focus-within,
    &:focus-visible,
    &:active {
      border-color: @dart-primary;
      box-shadow: 0 0 0 2px fade(@dart-primary, 20%);
      &:hover{
        border-color: @dart-primary;
        box-shadow: 0 0 0 2px fade(@dart-primary, 20%);
      }
    }

    &:hover{
      border-color: fade(@dart-primary, 40%);
      box-shadow: 0 0 0 2px fade(@dart-primary, 5%)
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";