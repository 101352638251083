.poa-overview-widget{
  margin-top: 0;
  .poa-overview-single{
    border-radius: 16px;
    background-color: @dart-light;
    padding: 16px;
    margin-bottom: 16px;
    .top{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;

      .left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 16px;
      }

      .title {
        font-family: 'GothamRegular', sans-serif;
        font-weight: 450;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: @dart-dark;
      }
      .extra{
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        color: @dart-dark;
      }
    }
    .bottom{
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      .expand-content.visible{
        margin-top: 0;
      }
      .expand-content{
        .BaseTable__body{
          height: 500px !important;
        }
        .BaseTable__table-frozen-left {
          min-height: 568px !important;
        }
        .store-header-cell{
          display: flex;
          column-gap: 16px;
          padding-left: 15px;
          .dart-checkbox.xs .checkbox-title{
            padding-left: 8px;
            font-family: 'GothamLight', sans-serif;

            font-weight: 325;
            font-size: 12px;
            line-height: 18px;
            color: white;
          }
        }
        .class-header-cell,
        .style-header-cell,
        .custom-header-cell {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #FFFFFF;
        }
        .poa-editable-cell{
          border: 1px solid @dart-gray-alt;
          border-top: none;
          padding: 0;
          position: relative;
          .dart-editable-cell{
            cursor: pointer;
          }
          &.disable-editing{
            .dart-editable-cell{
              cursor: initial;
            }
          }
            .dart-editable-cell:hover  .view-mode-wrapper{
              text-decoration: underline;
            }
        }
        .poa-fixed-cell{
          border: 1px solid @dart-gray-alt;
          border-top: none;
        }
        .poa-overview-table-cell:not(.frozen){
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .poa-overview-table-cell.updated{
          background-color: #E8F4EF;
          height: 100%;
          width: 100%;
        }
        .poa-overview-table-cell.flagged{
          background-color: #FFEAEA !important;
          height: 100%;
          width: 100%;
        }
        .poa-overview-table-cell.frozen{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 8px;
            .store-name{
              max-width: 175px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span{
              font-family: 'GothamLight', sans-serif;

              font-weight: 325;
              font-size: 12px;
              line-height: 18px;
              color: @dart-dark;
              &.vertical-splitter{
                height: 16px;
                width: 1px;
                background-color: @dart-gray;
                display: block;
              }
              &.store-status{
                width: 8px;
                height: 8px;
                border-radius: 50%;
              }
            }
          }
          .right{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            .contribution{
              font-size: 12px;
              line-height: 18px;
              color: @dart-dark;
              &.flagged{
                color: red;
              }
            }
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";