.table-row-menu-overlay{
  margin-top: -44px;
  margin-left: -23px;
  background-color: white;
  padding: 24px;
  box-shadow: 4px 8px 32px rgba(51, 62, 71, 0.16);
  border-radius: 16px;
  min-width: 280px;
  &.sister-store{
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: 34px;
    padding: 8px;
    margin-top: -32px;
    margin-left: -8px;
    min-width: unset;
    border-radius: 8px;
    .vertical-splitter{
      width: 1px;
      height: 16px;
      background-color: #C3C5C8;
    }
  }
  &.disable-editing{
    height: 64px;
  }
  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: -24px;
    left: 30px;
    li{
      display: flex;
      column-gap: 10px;
      height: 24px;
      margin-bottom: 16px;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: @dart-dark;
      user-select: none;
      &:hover{
        cursor: pointer;
      }
      &.disabled{
        color: @dart-mid-gray;
        &:hover{
          text-decoration: none;
          cursor: default;
        }
        .dart-icon *{
          fill: @dart-mid-gray
        }
      }
    }
  }
}

.table-row-menu {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";