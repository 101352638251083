.selected-style-color-size-picker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
    .label{
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: @dart-dark;
      user-select: none;
    }
    .sizes-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 12px;
      .size{
        background: @dart-light;
        border-radius: 8px;
        width: 56px;
        height: 32px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        user-select: none;
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 30px;
        color: #000000;
        border: 1px solid transparent;
        position: relative;
        &.active{
          background: transparent;
          border: 1px solid @dart-primary;
        }
        &.is-over-allocated:before{
          content: "";
          position: absolute;
          background: #EF1919;
          border: 1px solid #EF1919;
          width: 4px;
          height: 4px;
          bottom: 6px;
          border-radius: 50%;
        }
      }
    }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";