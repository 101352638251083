.uploaded-file-list-filters-block {
  margin-top: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  .ant-form-item{
    margin-bottom: 0;
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";