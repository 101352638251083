.alloc-q-card {
  background: #F6F6F6;
  border-radius: 16px;
  &:not(.ant-skeleton) {
    padding: 32px;
  }
  &.ant-skeleton{
    width: 100%;
    height: 200px;
    .ant-skeleton-avatar {
      border-radius: 16px;
      width: 100%;
      height: 200px;
    }
  }
  &.full{
    &.ant-skeleton{
      height: 400px;
      .ant-skeleton-avatar {
        height: 400px;
      }
    }
  }
  &.half{
    &.ant-skeleton{
      height: 300px;
      .ant-skeleton-avatar {
        height: 300px;
      }
    }
  }
  .card-header{
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    position: relative;
    justify-content: space-between;
    row-gap: 6px;
    cursor: pointer;
    .date{
      font-family: 'GothamBold', sans-serif;
      font-weight: 450;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: #333E47;
    }
    .updated-date{
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
    }
    .details-button{
      position: absolute;
      right: 0;
      *{
        fill: #000000;
      }
    }
  }
  hr{
    border: 0;
    border-bottom: 1px solid #E1E2E3;
    margin: 16px 0;
  }
  .card-body{
    .totals{
      display: flex;
      column-gap: 32px;
      > div {
        display: flex;
        flex-direction: column;
        width: 147px;
        height: 66px;
        .value{
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 32px;
          line-height: 38px;
          color: #333E47;
        }
        .label{
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: #000000;
        }
      }
    }
  }

  &.full,
  &.half{
    .card-header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .card-body{
      .totals{
        > div {
          .value{
            font-size: 40px;
            line-height: 48px;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";