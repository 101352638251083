.readiness-table-block {
  &.completed .BaseTable__row.BaseTable__row--customized,
  .BaseTable__row.BaseTable__row--depth-0 {
    height: 95px !important;
  }

  .BaseTable__row--depth-1 {
    padding: 20px 0;

    .BaseTable__header-cell-text {
      font-family: 'GothamLight', sans-serif;

      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: @dart-dark;
    }

    .BaseTable__header-row {
      border-bottom: 1px solid @dart-gray;

      .BaseTable__header-cell {
        display: flex;
        align-items: flex-start;
      }
    }

    .BaseTable__header-cell--align-center,
    .BaseTable__row-cell--align-center {
      border-right: 1px solid @dart-gray;
    }

    .BaseTable__row {
      height: 35px !important;
      font-family: 'GothamLight', sans-serif;

      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: @dart-dark;
      border-bottom: 1px solid @dart-gray;

      .readiness-table-cell {
        &.bold {
          font-family: "GothamBold", sans-serif;
          color: @dart-primary;
        }
      }
    }

    &:hover {
      background-color: white !important;
    }
  }

  .BaseTable__row {
    font-family: 'GothamRegular', sans-serif;

    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;

    .row-selection-checkbox {
      display: flex;
      column-gap: 16px;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .dart-table-expand-icon {
        position: initial;
        right: 0;
      }
    }

    .uuid-cell {
      position: relative;
      color: @dart-dark;
      display: flex;
      column-gap: 24px;
      align-items: center;

      .wrapper {
        width: 140px;
      }

      .uuid {
        font-family: 'GothamBold', sans-serif;

        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .date {
        font-family: 'GothamLight', sans-serif;

        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        position: absolute;
        bottom: -8px;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";