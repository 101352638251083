.store-profile-view {
  .store-profile-form-action{
    margin-top: 64px;
  }
  .spin-loader{
    position: absolute;
    top: 50%;
    left:0;
    right: 0;
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";