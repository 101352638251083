.dart-input {
  .dart-input-label {
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-left: 8px;
    display: block;
    height: 24px;
  }

  .dart-input-target {
    background: #F6F6F6 !important;
    border-radius: 8px;
    height: 48px;
    border: 0;

    &.has-action {
      .ant-btn,
      .ant-btn:focus,
      .ant-btn:hover {
        border: none !important;
        box-shadow: none !important;
        --antd-wave-shadow-color: @dart-primary;
      }

      .ant-input-suffix {
        padding-left: 7px;
        border-left: 1px solid @dart-gray;
      }
    }
  }

  .dart-input-description {
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 12px;
    line-height: 14px;
    color: #333E47;
    margin-top: 16px;
  }

  .ant-input,
  .ant-input[disabled] {
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: @dart-dark;
    padding: 4px 16px;
  }

  .dart-icon {
    margin-top: -2px;
  }

  &.transparent {
    .dart-input-target {
      background: transparent !important;
      border-radius: 8px;
      height: 48px;
      border: 2px solid @dart-gray-alt;
    }
  }

  .ant-input::placeholder {
    color: @dart-dark;
    opacity: 1;
  }

  &.transparent {
    .ant-input:focus, .ant-input-focused {
      border: 2px solid fade(@dart-primary, 20%);
      box-shadow: none;
    }

    .has-action{
      &.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused {
        box-shadow: none;
        border: 2px solid fade(@dart-primary, 20%);
      }
    }
  }

  .ant-input:focus, .ant-input-focused {
    box-shadow: 0 0 0 2px fade(@dart-primary, 20%);
  }

  .has-action {
    .ant-input:focus, .ant-input-focused {
      box-shadow: none;
      border: none;
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";