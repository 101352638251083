.size-profile-edit-page {
  .dart-page-title {
    height: 72px;
    margin-top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .left{
      .back-button{
        margin-left: 0;
      }
    }

    .right {
      position: initial;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }

    .center {
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: @dart-dark;
    }

    .size-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
      text-transform: initial;

      .dart-icon {
        margin-top: -2px;
      }
    }
  }

  .size-profile-filters {
    display: flex;
    align-items: center;

    &.open {
      .bottom {
        height: 216px;
        opacity: 1;
        transition: 0.3s;
      }
    }

    .container-content {
      width: 100%;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      width: 100%;

      .left {
        display: flex;
        column-gap: 16px;
        align-items: center;

        .ant-btn {
          display: flex;
          column-gap: 18px;
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          border: none;
          outline: none;
          box-shadow: none;
          height: 24px;
          padding: 3px;
          box-sizing: content-box;

          .dart-icon {
            margin-top: 1px;
          }
        }

        .vertical-splitter {
          width: 1px;
          height: 16px;
          background-color: @dart-gray;
        }

        .filtered-store-counter {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 16px;
          line-height: 24px;
          color: @dart-mid-gray;
        }
      }

      .right {
        display: flex;
        .discard-changes-button{
          .ant-btn {
            font-family: 'GothamLight', sans-serif;
            font-weight: 325;
            font-size: 16px;
            line-height: 24px;
            text-decoration-line: underline;
            color: @dart-dark;
            background-color: transparent;
            text-transform: initial;
            box-shadow: none;
            width: 132px;
            padding: 0 15px;
            box-sizing: content-box;
            margin-right: 16px;
          }
        }
      }
    }

    .close-button {
      outline: none;
      border: none;
      box-shadow: none;
    }

    .bottom {
      height: 0;
      opacity: 0;
      transition: 0.3s;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .filter-input-wrapper {
        display: flex;
        column-gap: 32px;

        .dart-select{
          .ant-select-selector{
            line-height: 48px;
            height: 48px;
            input{
              height: 48px;
            }
          }
          .ant-select-selection-item{
            line-height: 48px;
          }
          .ant-select-selection-placeholder{
            line-height: 48px;
          }
        }

        .dart-select-label,
        .label {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }

        .checkbox-group-wrap {
          margin-top: 4px;
          display: flex;
          column-gap: 32px;
          .checkbox-title{
            font-family: 'GothamLight', sans-serif;
            font-weight: 325;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }
      }
    }
    .filters-splitter{
      margin: 32px 0;
      border: none;
      border-bottom: 1px solid @dart-gray-alt;
    }
    .apply-filters-button {
      .ant-btn {
        padding: 16px 32px;
        max-width: 1028px;
        margin: 0 auto;
        width: 100%;
        height: 48px;
        background: @dart-dark;
        border-radius: 8px;

      }
    }
  }

  .size-profile-edit-table {
    &.condense{
      .BaseTable__body {
        height: calc(100vh - 496px) !important;
      }
      .BaseTable__table-frozen-left {
        min-height: calc(100vh - 496px) !important;
      }
    }
    .BaseTable__body {
      height: calc(100vh - 280px) !important;
    }

    .BaseTable__table-frozen-left {
      min-height: calc(100vh - 280px) !important;
    }

    .size-profile-editable-cell {
      border: 1px solid @dart-gray-alt;
      border-top: none;
      padding: 0;
      position: relative;

      .dart-editable-cell {
        cursor: pointer;
      }

      &.disable-editing {
        .dart-editable-cell {
          cursor: initial;
        }
      }

      .dart-editable-cell:hover .view-mode-wrapper {
        text-decoration: underline;
      }
    }

    .size-profile-fixed-cell {
      border: 1px solid @dart-gray-alt;
      border-top: none;
    }

    .size-run-overview-table-cell:not(.frozen) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .size-run-overview-table-cell.updated {
      background-color: #E8F4EF;
      height: 100%;
      width: 100%;
    }

    .size-run-overview-table-cell.flagged {
      background-color: #FFEAEA !important;
      height: 100%;
      width: 100%;
    }

    .size-run-overview-table-cell.frozen {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 8px;

        span {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: @dart-dark;

          &.vertical-splitter {
            height: 16px;
            width: 1px;
            background-color: @dart-gray;
            display: block;
          }

          &.store-status {
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;

        .contribution {
          font-size: 12px;
          line-height: 18px;
          color: @dart-dark;
          cursor: pointer;
          &.flagged {
            color: red;
          }
        }

        .table-row-menu {
          right: 48px !important;
        }
      }
    }
  }
}

// used for top header
.main-layout-content {
  .size-profile-edit-page{
    padding-top: 72px;
    .container-content{
      padding: 0 64px;
    }
    .dart-page-title{
      background-color: @dart-light;
      position: fixed;
      width: 100vw;
      top: 94px;
      .left{
        .back-button{
          margin-left: 64px;
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";