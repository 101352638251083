.top-menu-wrapper{
  z-index: 99;
}
.dart-top-menu {
  height: 64px;
  padding: 12px 64px;
  display: flex;
  z-index: 3;
  background-color: white;
  .right,
  .left {
    height: 40px;
  }

  .left {
    display: flex;
    justify-content: left;
    align-items: center;
    column-gap: 24px;
    flex: 1;

    .menu {
      display: flex;
      flex-direction: row;
      column-gap: 24px;

      a {
        font-family: 'GothamBlack', sans-serif;
        font-weight: 450;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: @dart-dark;
      }

      .anticon {
        transition: 0.2s;
      }

      .open {
        .anticon {
          transition: 0.2s;
          transform: rotate(180deg);
        }
      }
    }

    .logo {
      width: 91px;
      > div{
        margin: 0;
        font-family: "GothamRegular", sans-serif;
        font-weight: 450;
        font-size: 12px;
        line-height: 12px;
        .ant-skeleton{
          height: 8px;
          overflow: hidden;
          width: 60px;
          margin-top: 3px;
        }
      }
    }

    .to-dashboard-btn {
      width: 48px;
      height: 48px;
      background: @dart-light;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transition: 0.3s;
        box-shadow: inset 0px 0px 15px -10px rgba(0, 0, 0, 0.45);
      }
    }

    .ant-menu-horizontal {
      min-width: 200px;
      max-width: 300px;
    }
  }

  .right {
    display: flex;
    justify-content: right;
    align-items: center;
    flex: 1;

    .ant-menu-horizontal {
      min-width: 200px;
      max-width: 300px;
    }

    .menu {
      display: flex;
      align-items: center;
      column-gap: 28px;
      .profile-menu-wrapper{
        padding-bottom: 16px;
        .info{
          padding: 16px 12px 0 12px;
          display: flex;
          flex-direction: row;
          column-gap: 12px;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid @dart-gray;
            background-color: @dart-light;
          }
          .email{
            font-family: 'GothamLight', sans-serif;
            font-style: normal;
            font-weight: 450;
            font-size: 12px;
            line-height: 12px;
            color: @dart-dark;
          }
        }
        a{
          padding: 8px 12px;
          text-align: center;
          overflow: hidden;
          transition: 0.2s;
          &:hover{
            transition: 0.2s;
            background-color: @dart-gray-alt;
          }
        }
      }
    }
  }
}

.dart-global-filters-row{
  height: 30px;
  background: white;
  width: 100%;
  padding: 0 64px;
  display: flex;
  justify-content: space-between;
  .vertical-separator{
    width: 1px;
    height: 16px;
    margin: 7px 12px;
    background: @dart-gray;
  }
  .left{

  }
  .right{
    display: flex;
    justify-content: flex-end;

    .region-wrapper{
      display: flex;
      justify-content: center;
      font-family: 'GothamRegular', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 12px;
      line-height: 30px;
      text-transform: uppercase;
      color: @dart-dark;
    }

    .global-filters-form{
      display: flex;
      align-items: center;
      height: 30px;
      .ant-form-item-row{
        .ant-form-item-control{
          .ant-form-item-control-input{
            min-height: 30px;
          }
        }
      }
      .ant-form-item{
        margin-bottom: 0;
        height: 30px;
        .ant-select-selector,
        .ant-select-selection-item,
        .ant-select-selection-placeholder{
          font-family: 'GothamRegular', sans-serif;
          font-style: normal;
          font-weight: 450;
          font-size: 12px;
          line-height: 30px;
          text-transform: uppercase;
          color: @dart-dark;
          height: 30px;
        }
        .dart-compact-select .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder{
          color: @dart-dark;
        }
      }
    }
  }
  .ant-skeleton-element{
    width: 80px;
    height: 24px;
    margin-top: 3px;
    overflow: hidden;
  }
  .ant-skeleton-input{
    height: 30px;
    width: 80px;
  }
}

.dart-top-menu-bottom-section {
  position: absolute;
  width: 100%;
  top: 64px;
  background-color: @dart-light;
  z-index: 50;
  margin-top: -64px;
  opacity: 0;
  transition: 0.3s;
  padding-left: 231px;

  &.shown {
    opacity: 1;
    margin-top: 0;
    transition: 0.3s;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: fade(@dart-primary, 8%);
  }

  .ant-menu-horizontal {
    border: 0;
    background-color: @dart-light;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    color: @dart-dark;
  }

  .ant-menu-submenu-title {
    color: @dart-dark !important;
  }

  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    color: @dart-primary !important;
    > .ant-menu-submenu-title span{
      color: @dart-primary !important;
    }
  }

  .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
    border: 0 !important;
  }

  .ant-menu {
    > li {
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 12px;
      line-height: 52px;
      text-transform: uppercase;
      color: @dart-dark;

      .ant-menu-submenu-popup {
        ul {
          background: @dart-light;
          box-shadow: 4px 8px 16px rgba(51, 62, 71, 0.16);
          border-radius: 16px;

          li:not(.ant-menu-item-disabled) {
            color: @dart-dark;
          }
        }
      }
    }

    > ul {
      > li {
        border: none !important;
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: #000000;
      }
    }
  }

  .ant-menu-submenu:not(.ant-menu-submenu-hidden),
  .ant-menu-item {
    display: flex;
    align-items: center;

    .ant-menu-title-content {
      text-transform: uppercase;
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-arrow {
      display: inline-block;
      right: 12px;
      color: @dart-mid-gray !important;
      transform: rotate(90deg);
      transition: 0.2s;
    }

    &-open {
      .ant-menu-submenu-arrow {
        transform: rotate(270deg);
        transition: 0.2s;
      }
    }
  }

  .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-right: 32px;
  }
}

.dart-top-menu,
.dart-top-menu-bottom-section {
  .ant-dropdown {
    background: @dart-light;
    box-shadow: 4px 8px 32px rgba(51, 62, 71, 0.16);
    border-radius: 16px;

    a {
      display: block;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
      padding: 24px;
    }
  }
}

.dart-top-menu-bottom-section-popup {
  border-radius: 16px !important;

  > ul {
    background: @dart-light !important;
    box-shadow: 4px 8px 32px rgba(51, 62, 71, 0.16) !important;
    border-radius: 16px !important;
  }
}

@media (max-width: 991px) {
  .dart-top-menu {
    padding: 12px 16px;

    .left {
      .menu {
        column-gap: 12px;
      }
    }

    .right {
      .menu {
        column-gap: 12px;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";