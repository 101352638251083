.store-list-layout {
  padding-top: 42px;
}

// used for top header
.main-layout-content {
  .store-list-layout{
    padding: 32px 64px 0 64px;
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";