.store-list-empty{
  height: 160px;
  background-color: @dart-light;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 18px;
  font-family: 'GothamLight', sans-serif;

  font-weight: 325;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";