.dart-table {
  .expand-toggle-button,
  .select-toggle-button {
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    color: @dart-dark;
    user-select: none;
    position: absolute;
    top: 20px;
    z-index: 9;
    padding-right: 16px;
    background: white;
  }
  .table-loader{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 5;
  }
  .expanded-row-child,
  .child-row-renderer {
    .table-loader{
      display: none !important;
    }
  }
  .row-selection-checkbox {
    justify-content: center;
  }

  &.border-top {
    border-top: 1px solid #7CA6BB;
  }

  div:not(.child-row-renderer) .BaseTable__row:not(.BaseTable__row--depth-1, .BaseTable__row--depth-2, .BaseTable__row--depth-3, .BaseTable__row--depth-4),
  div:not(.child-row-renderer) .BaseTable__row--depth-0 {
    height: 50px;
  }

  .child-row-renderer {
    width: 100%;
    overflow: auto auto;
  }

  div:not(.child-row-renderer) .BaseTable__row:not(.BaseTable__row--depth-1, .BaseTable__row--depth-2, .BaseTable__row--depth-3, .BaseTable__row--depth-4),
  div:not(.child-row-renderer) .BaseTable__row--depth-0 {
    .BaseTable__row-cell {
      position: relative;
    }
  }

  .BaseTable__header-row, .BaseTable__row {
    border-bottom: 1px solid @dart-gray-alt;
  }

  .BaseTable__row--depth-1 {
    .BaseTable {
      .BaseTable__row {
        height: 50px;
      }
    }
  }

  .dart-table-expand-icon {
    position: absolute;
    right: 12px;
    cursor: pointer;
  }

  .row-expand-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 3px;

    &:hover {
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.05);
    }

    .dart-icon {
      transform: rotateZ(0deg);
      width: 14px;
      height: 14px;
    }

    &.is-expanded {
      .dart-icon {
        transform: rotateZ(180deg);
      }
    }
  }

  .ant-empty {
    margin-top: 64px;
  }

  .BaseTable {
    box-shadow: none;

    .BaseTable__table-main {
      outline: none;
    }
  }

  .BaseTable__table-main .BaseTable__header {
    background: transparent;
  }

  .BaseTable__header-row {
    background: transparent;
  }

  .BaseTable__header-cell-text {
    font-family: 'GothamLight', sans-serif;

    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;
  }

  .BaseTable__row-cell {
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: @dart-dark;
  }

  .BaseTable__row:hover, .BaseTable__row--hovered {
    background: fade(@dart-primary, 3%);
  }

  &.dark{
    .BaseTable__row-cell {
      border-bottom: 1px solid @dart-gray-alt;
    }
    .BaseTable__row:hover, .BaseTable__row--hovered{
      background-color: inherit !important;
    }
    .BaseTable__table-frozen-left{
      box-shadow: none;
      .BaseTable__row-cell{
        padding: 0 16px;
      }
      .BaseTable__header-cell{
        border-right: 1px solid #000000 !important;
        padding: 0 16px;
      }
    }
    .BaseTable__row:first-child{
      border-top: 2px solid @dart-gray-alt;
    }
    .BaseTable__header{
      height: 34px !important;
    }
    &.double-header{
      .BaseTable__header{
        height: 68px !important;
      }
    }
    .BaseTable__header-row{
      height: 34px !important;
      background-color: @dart-dark;
      border-bottom: 1px solid @dart-dark;
    }
    .BaseTable__header-cell{
      height: 34px;
      background-color: @dart-dark;
      border-right: 1px solid #000000;
      .BaseTable__header-cell-text{
        color: white;
        font-family: 'GothamLight', sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .BaseTable__header-cell-text{
      font-weight: 600;
      width: 100%;
      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    .BaseTable__header{
      > div {
        > .BaseTable__header-row {
          > div {
            &:first-child {
              border-left: 1px solid #000000 !important;
            }
          }
        }
        > .BaseTable__header-row:first-child{
          > div{
            &:first-child{
              justify-content: flex-start;
              border-left: 1px solid #000000 !important;
            }
            border: 1px solid #000000;
            border-left: none;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";