.alloc-q-comments-sider {
  .empty-comments{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
    row-gap: 16px;
  }
  .comments-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px !important;
    flex-direction: column;
    row-gap: 16px;
    column-gap: 16px;
    .ant-skeleton{
      width: 100%;
      .ant-skeleton-input{
        width: 100%;
        background: #F6F6F6;
        border-radius: 16px;
        padding: 16px;
        height: 89px;
      }
    }
  }
  .comments-loading-single{
    .ant-skeleton{
      width: 100%;
      .ant-skeleton-input{
        width: 100%;
        background: #F6F6F6;
        border-radius: 16px;
        padding: 16px;
        height: 89px;
      }
    }
  }
  .sidebar-header-wrapper {
    padding: 12px 24px !important;

    .cc-name {
      font-family: "GothamBold", sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: @dart-dark;
      line-height: 24px;
    }

    .cc-details {
      font-family: "GothamLight", sans-serif;
      font-size: 12px;
      font-weight: 325;
      color: @dart-dark;
      line-height: 14px;
    }
  }

  .comments-wrapper {
    .comments {
      display: flex;
      flex-direction: column;
      padding: 24px;
      row-gap: 16px;
      column-gap: 16px;

      .comment {
        display: flex;
        column-gap: 8px;
        justify-content: flex-start;
        background: #F6F6F6;
        border-radius: 16px;
        padding: 16px;

        .left {
          width: 40px;
        }

        .ant-avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid white;
          background: white;
        }

        .author {
          display: flex;
          column-gap: 6px;
          b{
            font-family: "GothamBold", sans-serif;
            font-size: 14px;
            font-weight: 325;
            color: @dart-dark;
            line-height: 20px;
          }
          span{
            font-family: "GothamLight", sans-serif;
            font-size: 14px;
            font-weight: 325;
            color: @dart-dark;
            line-height: 20px;
          }
        }

        .date {
          font-family: "GothamThin", sans-serif;
          font-size: 12px;
          font-weight: 325;
          color: @dart-dark;
          line-height: 12px;
        }

        .text {
          font-family: "GothamLight", sans-serif;
          font-size: 14px;
          font-weight: 325;
          color: @dart-dark;
          line-height: 1.2;
          margin-top: 8px;
        }
      }
    }
  }

  .sidebar-footer-wrapper {
    .comment-add-wrapper {
      width: 100%;
      display: flex;
      column-gap: 8px;
      justify-content: space-between;
      align-items: center;

      .dart-input {
        flex: 1;
      }

      .dart-button {
        height: 48px;

        .ant-btn {
          height: 48px;
          width: 48px;
          padding: 0;

          .ant-spin {
            display: flex;

            .ant-spin-dot-item {
              background-color: white;
            }
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";