.dart-prompt {
  .ant-modal {
    max-width: 800px;
  }

  .splitter{
    border: 0;
    border-bottom: 1px solid #E1E2E3;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
  }

  .list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    p {
      margin-bottom: 0;
    }
    > * {
      font-family: 'GothamLight', sans-serif;
      font-style: normal;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
    }
  }

  .ant-modal-close {
    right: 32px;
    top: 32px;
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    right: 32px;

    .dart-icon * {
      font-size: 14px;
      fill: @dart-gray;
    }
  }

  .ant-modal-content {
    border-radius: 16px;
    background-color: white;
    padding: 32px;
    text-align: center;

    .ant-modal-header {
      padding: 0;
      border: 0;

      .head-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 28px;
        justify-content: flex-end;
        margin-top: 51px;
        align-items: center;

        .anticon {
          font-size: 40px !important;
        }
      }

      .ant-modal-title {
        font-family: 'GothamBlack', sans-serif;

        font-weight: 450;
        font-size: 32px;
        line-height: 32px;
        color: @dart-dark;
        text-transform: uppercase;
      }
    }

    .ant-modal-body {
      margin: 0;
      font-family: 'GothamLight', sans-serif;

      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: @dart-dark;
      padding: 24px 0 64px 0;
    }

    .custom-footer {
      .discard-button{
        .ant-btn{
          height: 48px !important;
          margin-top: -12px !important;
          margin-bottom: -12px !important;
        }
      }
    }

    .ant-modal-footer,
    .custom-footer {
      padding: 0;
      border: 0;
      display: flex;
      flex-direction: column-reverse;
      row-gap: 24px;
      column-gap: 24px;

      .ant-btn {
        width: 100%;
        border: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        margin: 0 !important;

        &.ant-btn-primary {
          font-family: 'GothamBlack', sans-serif;
          font-weight: 450;
          font-size: 16px;
          line-height: 16px;
          text-transform: uppercase;
          color: #FFFFFF;
          height: 48px;
          background: @dart-dark;
          &[disabled]{
            background-color: @dart-gray-alt !important;
          }
        }

        &.ant-btn-default {
          background-color: transparent !important;
          text-decoration: underline;
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: @dart-dark;
          border: none !important;
          box-shadow: none !important;
          height: 24px;
          --antd-wave-shadow-color: transparent !important;
        }
      }
      .discard-button{
        display: flex;
        flex-direction: row;
        column-gap: 24px;
        > div:first-child {
          flex: 1;
        }
        > div:last-child {
          flex: 3;
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";