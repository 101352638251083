.alloc-q-style-details-modal {
  .ant-modal-header {
    display: none;
  }
  .iaq-stores-with-stages-wrapper{
    display: flex;
    flex-direction: row;
  }
  .iaq-store-stage-buttons{
    margin-top: 8px;
    .button-group{
      display: flex;
      column-gap: 8px;
      justify-content: flex-start;
      a{
        display: flex;
        align-items: center;
        border: 1px solid @dart-gray;
        padding: 3px 6px;
        border-radius: 8px;
        color: @dart-dark !important;
        transition: 0.2s;
        &:hover{
          border: 1px solid @dart-primary;
          transition: 0.2s;
        }
      }
      .dart-icon{
        margin-right: 6px;
      }
    }
  }
  .iaq-store-stages-wrapper{
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 12px;
    .stages-title{
      font-size: 12px;
      margin-bottom: 16px;
      margin-top: 4px;
    }
    .ant-steps-item.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
      filter: grayscale(1);
    }
    .ant-steps-item > .ant-steps-item-container > .ant-steps-item-icon {
      background: transparent;
      border: 1px solid @dart-primary;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-steps-icon{
        color: @dart-dark;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
        }
      }
    }
    .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail{
      left: 19px;
      &:before,
      &:after{
        background-color: @dart-gray !important;
      }
    }
    .ant-steps-item.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
      background: @dart-primary;
      border: 1px solid @dart-primary;
      .ant-steps-icon{
        color: white;
        user-select: none;
        span {
          font-weight: bold;
        }
      }
    }
    .ant-steps-item.ant-steps-item-wait.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
      filter: grayscale(1);
    }
    .ant-steps-item-container {
      height: 50px;
      display: flex;
      justify-content: center;
      .ant-steps-item-icon {
        margin-right: 0;
        padding-right: 0;
      }
      .ant-steps-item-content {
        display: none;
      }
    }
  }
  .iaq-store-list-wrapper{
    flex: 6;
  }

  &.dart-prompt .ant-modal-content .ant-modal-body {
    padding-top: 8px;
  }

  &.dart-prompt .ant-modal-footer .ant-btn.ant-btn-primary {
    background-color: @dart-primary;
  }

  .alloc-q-style-details-modal-content {
    > .ant-row:first-child {
      margin-bottom: 16px;
    }

    .BaseTable__empty-layer{
      left: 1px;
      height: 300px;
    }

    .ant-segmented{
      margin-top: -24px;
      border-radius: 8px;
      .totals{
        margin: 8px 0;
        * {
          user-select: none;
        }
      }
      .ant-segmented-thumb{
        border-radius: 8px;
      }
      .ant-segmented-item{
        border-radius: 8px;
        .label,
        .value {
          color: @dart-dark;
          transition: 0.2s;
        }
      }
      .ant-segmented-item-selected{
        background-color: @dart-dark;
        border-radius: 8px;
        .label,
        .value {
          color: white !important;
          transition: 0.2s;
        }
      }
      .ant-segmented-item-label{
        height: 100%;
      }
      .ant-segmented-thumb-motion-appear{
        background-color: @dart-dark;
        border-radius: 8px;
        .label,
        .value {
          color: white !important;
          transition: 0.2s;
        }
      }
    }

    .totals-wrapper{
      display: flex;
      column-gap: 16px;
      height: 100%;
      .totals.lg {
        .value {
          font-weight: 325;
          font-size: 24px;
          line-height: 38px;
        }
      }
      .totals.sm {
        .value {
          font-weight: 325;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    .totals {
      height: calc(100% - 16px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin-top: -13px;

      &.lg {
        .value {
          font-family: 'GothamMedium', sans-serif;
          font-weight: 325;
          font-size: 32px;
          line-height: 38px;
          color: #333E47;
          transition: 0.2s;
        }
      }

      &.sm {
        .value {
          font-family: 'GothamMedium', sans-serif;
          font-weight: 325;
          font-size: 24px;
          line-height: 29px;
          color: #333E47;
          transition: 0.2s;
        }
      }

      .value {
        .ant-input-number-focused{
          box-shadow: none;
        }
        .ant-input-number {
          text-align: center;
          width: 128px;
          font-family: 'GothamMedium', sans-serif;
          font-weight: 325;
          font-size: 24px;
          line-height: 29px;
          color: #333E47;
          padding: 0;
          background-color: white;
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
          input{
            text-align: center;
          }

          &.dart-input-target {
            background: transparent !important;
            padding: 0;
            height: unset;
          }
        }
      }

      .label {
        font-family: 'GothamLight', sans-serif;
        font-style: normal;
        font-weight: 325;
        font-size: 12px;
        line-height: 12px;
        color: #000000;
      }

      .horizontal-splitter {
        height: 1px;
        background-color: @dart-dark;
        width: 88px;
        margin: 4px 0;
      }
    }

    .style-info {
      color: black;
      font-family: 'GothamLight', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      margin-top: -24px;

      .vertical-splitter {
        color: #C3C5C8;
      }

      .date,
      .allocated-to,
      .tag {
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        column-gap: 8px;
        align-items: center;

        .fade {
          color: @dart-dark;
        }
      }
      .allocated-to{
        a{
          color: @dart-dark;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      .tag.has-tag {
        font-family: "GothamRegular", sans-serif;
      }
    }
    .BaseTable__row-cell{
      &.no-padding {
        padding: 0;
      }
      .style-details-table-cell.center{
        height: 47px;
        display: flex;
        justify-content: center;
        &.has-change{
          background-color: #E8F4EF;
        }
      }
      .dart-editable-cell{
        display: flex;
        justify-content: center;
        cursor: pointer;
        &:hover {
          * {
            text-decoration: underline
          }
        }
      }
    }
    .BaseTable__row-cell:not(:last-child){
      border-right: 1px solid #E1E2E3;
    }
    .BaseTable__header-cell:first-child {
      text-align: left;
    }

    .BaseTable__table{
      border-left: 1px solid #E1E2E3;
      border-right: 1px solid #E1E2E3;
      border-bottom: 1px solid #E1E2E3;

      .view-stages-button,
      .hide-stages-button{
        position: absolute;
        right: 0;
        top: 0;
        background: transparent;
        color: white;
        border: 0;
        .dart-icon{
          margin-right: 5px;
        }
      }
    }

    .BaseTable__header-cell:first-child .BaseTable__header-cell-text{
      padding-left: 15px;
    }

    .style-details-table-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .tag-wrapper{
        display: flex;
        align-items: center;
        column-gap: 8px;
        .dart-icon{
          margin-top: 4px;
        }
      }
      &.store-cell{
        height: 49px;
        padding-left: 15px;
        padding-right: 7.5px;
      }
      &.deselected {
        background-color: #F5F5F5 !important;
        .dart-editable-cell:hover{
          cursor: not-allowed;
          * {
            text-decoration: none
          }
        }
        > *:not(.right) {
          opacity: 0.3;
        }
      }
      &.excluded,
      &.deselected.excluded,
      &.selected.excluded{
        background-color: #ffdede !important;
      }
      &.center{
        text-align: center;
        display: initial;
      }
      .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 8px;

        .store-name {
          max-width: 245px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          font-family: 'GothamLight', sans-serif;

          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: @dart-dark;

          &.vertical-splitter {
            height: 16px;
            width: 1px;
            background-color: @dart-gray;
            display: block;
          }

          &.store-status {
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;

        .contribution {
          font-size: 12px;
          line-height: 18px;
          color: @dart-dark;

          &.flagged {
            color: red;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src3292027574/src/src/configs/variables.less";